import { useLocalStorage } from '@/hooks/use-storage';
import axios from 'axios';
export const strapiURL =
  process.env.GATSBY_STRAPI_URL || 'http://localhost:1337';
export const serverlessFnURL = '/.netlify/functions/';

// Helper to make GET requests to Strapi

export const strapiAPI = axios.create({
  baseURL: strapiURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const API = axios.create({
  baseURL: serverlessFnURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers = config.headers || {};

  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
});

export async function fetchAPI<T>(
  path: string,
  baseUrl: string = strapiURL,
  options = {},
) {
  const [lsToken] = useLocalStorage<string | undefined>('token', undefined);

  const defaultOptions: any = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (lsToken) {
    defaultOptions.headers['Authorization'] = `Bearer ${lsToken}`;
  }
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };
  const requestUrl = `${baseUrl}${path}`;
  try {
    const response = await fetch(requestUrl, mergedOptions);

    if (!response.ok) {
      console.error(path, response.statusText, response);
      const { error } = await response.json();
      throw new Error(error);
    }
    const data = await response.json();
    return data as T;
  } catch (error) {
    throw error;
  }
}
