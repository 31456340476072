import { put, call } from 'redux-saga/effects';
import {
  registerUser,
  loginUser,
  checkAuth,
  resetPassword,
} from '@/services/auth-service';
import { User } from '@/utils/types';
import {
  RegisterUserAction,
  LoginUserAction,
  loginUserSuccessAction,
  loginUserErrorAction,
  LoginAutoUserAction,
  ResetPasswordUserAction,
} from '../actions/auth-actions';
import { handleError } from './utils';

export function* registerSaga({ request }: RegisterUserAction) {
  try {
    const user: User = yield call(registerUser, request);
    yield put(loginUserSuccessAction(user));
  } catch (error) {
    yield handleError(error, loginUserErrorAction);
  }
}

export function* loginSaga({ request }: LoginUserAction) {
  try {
    const user: User = yield call(loginUser, request);
    yield put(loginUserSuccessAction(user));
  } catch (error) {
    yield handleError(error, loginUserErrorAction);
  }
}

export function* resetPasswordSaga({ request }: ResetPasswordUserAction) {
  try {
    const user: User = yield call(resetPassword, request);
    yield put(loginUserSuccessAction(user));
  } catch (error) {
    yield handleError(error, loginUserErrorAction);
  }
}

export function* loginAutoSaga({}: LoginAutoUserAction) {
  try {
    const user: User = yield call(checkAuth);
    yield put(loginUserSuccessAction(user));
  } catch (error) {
    yield handleError(error, loginUserErrorAction);
  }
}
