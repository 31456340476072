import { authReducer } from './auth-reducer';
import { themeReducer } from './theme-reducer';
import { combineReducers } from 'redux';
import { articlesReducer } from './articles-reducer';
import { metaReducer } from './meta-reducer';

export default combineReducers({
  auth: authReducer,
  theme: themeReducer,
  articles: articlesReducer,
  meta: metaReducer,
});
