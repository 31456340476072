import { put } from 'redux-saga/effects';

export function* handleError(error: any, action: (error: string) => any) {
  if (typeof error === 'string') {
    yield put(action(error));
  } else if (error instanceof Error) {
    yield put(action(error.message));
  } else {
    console.error(error);
  }
}
