import { Theme } from '@/models/theme';
import { useCookies } from 'react-cookie';
import { Reducer } from 'redux';

import {
  SetThemeAction,
  SET_THEME,
  ToggleDarkModeAction,
  TOGGLE_DARK_MODE,
} from '../actions/theme-actions';

export interface ThemeState {
  colorTheme: Theme;
}

const initialState: ThemeState = {
  colorTheme: 'light',
};

export type ThemeActions = ToggleDarkModeAction | SetThemeAction;

export const themeReducer: Reducer<ThemeState, ThemeActions> = (
  state = initialState,
  action,
) => {
  if (action.type === TOGGLE_DARK_MODE) {
    const newColorTheme = state.colorTheme === 'light' ? 'dark' : 'light';
    return {
      colorTheme: newColorTheme,
    };
  }

  if (action.type === SET_THEME) {
    return {
      colorTheme: action.payload,
    };
  }

  return state;
};
