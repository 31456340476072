import { Article } from '@/models/articles';

export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_ERROR = 'GET_ARTICLES_ERROR';

export interface GetArticlesAction {
  locale: string;
  type: typeof GET_ARTICLES;
}

export interface GetArticlesSuccessAction {
  type: typeof GET_ARTICLES_SUCCESS;
  articles: Article[];
}

export interface GetArticlesErrorAction {
  type: typeof GET_ARTICLES_ERROR;
  error: any;
}

export const getArticlesAction = (locale: string): GetArticlesAction => {
  return {
    type: GET_ARTICLES,
    locale,
  };
};

export const getArticlesSuccessAction = (
  articles: Article[],
): GetArticlesSuccessAction => {
  return {
    type: GET_ARTICLES_SUCCESS,
    articles,
  };
};

export const getArticlesErrorAction = (error: any): GetArticlesErrorAction => {
  return {
    type: GET_ARTICLES_ERROR,
    error,
  };
};
