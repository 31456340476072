import { Theme } from '@/models/theme';

export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const SET_THEME = 'SET_THEME';

export interface ToggleDarkModeAction {
  type: typeof TOGGLE_DARK_MODE;
}
export interface SetThemeAction {
  type: typeof SET_THEME;
  payload: Theme;
}

export const toggleDarkModeAction = (): ToggleDarkModeAction => {
  return {
    type: TOGGLE_DARK_MODE,
  };
};

export const setThemeAction = (payload: Theme): SetThemeAction => {
  return {
    type: SET_THEME,
    payload,
  };
};
