import { LoginReq, RegisterReq, ResetPasswordReq, User } from '@/utils/types';
import { API } from '@/utils/api';

interface Status {
  status: string;
}

export const loginUser = async (req: LoginReq) => {
  const { data } = await API.post<User>(`login`, req);
  return data;
};

export const registerUser = async (user: RegisterReq) => {
  const { data } = await API.post<User>(`register`, user);
  return data;
};

export const checkAuth = async () => {
  const { data } = await API.get<User>(`check-auth`);
  return data;
};

export const forgetPassword = async (email: string) => {
  const { data } = await API.post<Status>(`forget-password`, { email });
  return data;
};

export const resetPassword = async (req: ResetPasswordReq) => {
  const { data } = await API.post<User>(`reset-password`, req);
  return data;
};
