import { useState, useEffect, useCallback } from 'react';

interface StorageChangeEvent extends Event {
  detail?: {
    key: string;
  };
}

const IS_BROWSER =
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  typeof document !== 'undefined';

let evtTarget: EventTarget | undefined;

try {
  evtTarget = new EventTarget();
} catch {
  evtTarget =
    typeof document !== `undefined`
      ? document.createElement?.('phony')
      : undefined;
}

// SSR Storage simply returns defaultValue
const useSSRStorage =
  () =>
  <T>(
    _: string,
    defaultValue: T,
  ): [T | undefined, (updatedValue: T, remove?: boolean) => void, () => void] =>
    [defaultValue, (updatedValue: T, remove?: boolean) => {}, () => {}];

const useStorage =
  (storage: Storage) =>
  <T>(
    key: string,
    defaultValue: T,
  ): [
    T | undefined,
    (updatedValue: T, remove?: boolean) => void,
    () => void,
  ] => {
    const raw = storage.getItem(key);

    const [value, setValue] = useState<T | undefined>(
      raw ? JSON.parse(raw) : defaultValue,
    );

    const updater = useCallback(
      (updatedValue?: T, remove = false) => {
        if (updatedValue) {
          setValue(updatedValue);
        }

        if (remove) {
          storage.removeItem(key);
          setValue(undefined);
        } else {
          storage.setItem(key, JSON.stringify(updatedValue));
        }

        evtTarget?.dispatchEvent(
          new CustomEvent('storage_change', { detail: { key } }),
        );
      },
      [key],
    );

    defaultValue != null && !raw && updater(defaultValue);

    useEffect(() => {
      const listener = (event: StorageChangeEvent) => {
        if (event.detail?.key === key) {
          const lraw = storage.getItem(key);

          lraw !== raw && setValue(lraw ? JSON.parse(lraw) : null);
        }
      };

      evtTarget?.addEventListener('storage_change', listener);
      return () => evtTarget?.removeEventListener('storage_change', listener);
    });

    return [value, updater, () => updater(undefined, true)];
  };

export const useLocalStorage = IS_BROWSER
  ? useStorage(localStorage)
  : useSSRStorage();
export const useSessionStorage = IS_BROWSER
  ? useStorage(sessionStorage)
  : useSSRStorage();
