import { LoginReq, RegisterReq, ResetPasswordReq, User } from '@/utils/types';

export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_AUTO_USER = 'LOGIN_AUTO_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const RESET_PASSWORD_USER = 'RESET_PASSWORD_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export interface RegisterUserAction {
  request: RegisterReq;
  type: typeof REGISTER_USER;
}

export interface LoginUserAction {
  request: LoginReq;
  type: typeof LOGIN_USER;
}

export interface ResetPasswordUserAction {
  request: ResetPasswordReq;
  type: typeof RESET_PASSWORD_USER;
}

export interface LoginAutoUserAction {
  type: typeof LOGIN_AUTO_USER;
}

export interface LoginUserSuccessAction {
  type: typeof LOGIN_USER_SUCCESS;
  user: User;
}

export interface LoginUserErrorAction {
  type: typeof LOGIN_USER_ERROR;
  error: any;
}

export const registerUserAction = (
  request: RegisterReq,
): RegisterUserAction => {
  return {
    type: REGISTER_USER,
    request,
  };
};

export const loginUserAction = (request: LoginReq): LoginUserAction => {
  return {
    type: LOGIN_USER,
    request,
  };
};

export const resetPasswordUserAction = (
  request: ResetPasswordReq,
): ResetPasswordUserAction => {
  return {
    type: RESET_PASSWORD_USER,
    request,
  };
};

export const loginAutoUserAction = (): LoginAutoUserAction => {
  return {
    type: LOGIN_AUTO_USER,
  };
};

export const loginUserSuccessAction = (user: User): LoginUserSuccessAction => {
  return {
    type: LOGIN_USER_SUCCESS,
    user,
  };
};

export const loginUserErrorAction = (error: any): LoginUserErrorAction => {
  return {
    type: LOGIN_USER_ERROR,
    error,
  };
};

export const LOGOUT_USER = 'LOGOUT_USER';

export interface LogoutUserAction {
  type: typeof LOGOUT_USER;
}

export const logoutUserAction = (): LogoutUserAction => {
  return {
    type: LOGOUT_USER,
  };
};
