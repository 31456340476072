import { Meta } from '@/models/meta';

export const SET_META = 'SET_META';

export interface SetMetaAction {
  type: typeof SET_META;
  payload: Meta;
}

export const setMetaAction = (payload: Meta): SetMetaAction => {
  return {
    type: SET_META,
    payload,
  };
};
