import React, { FunctionComponent, ReactNode } from 'react';
import { CookiesProvider } from 'react-cookie';
import '@/styles/global.css';
import WrapStoreWithProvider from './wrap-with-provider';

interface WrapRootElementProps {
  element: ReactNode;
}

interface PluginOptions {
  key?: string;
  id?: string;
  text?: string;
}

// Adds a class name to the body element
export const onRenderBody = (
  { setPostBodyComponents }: any,
  pluginOptions: PluginOptions,
) => {
  setPostBodyComponents([
    <div
      key={pluginOptions.key ? pluginOptions.key : 'portal'}
      id={pluginOptions.id ? pluginOptions.id : 'portal'}
    >
      {pluginOptions.text}
    </div>,
  ]);
};

export const wrapRootElement: FunctionComponent<WrapRootElementProps> = ({
  element,
}) => (
  <CookiesProvider>
    <WrapStoreWithProvider>{element}</WrapStoreWithProvider>
  </CookiesProvider>
);
