import { Article } from '@/models/articles';
import { Reducer } from 'redux';
import {
  GetArticlesAction,
  GetArticlesErrorAction,
  GetArticlesSuccessAction,
  GET_ARTICLES,
  GET_ARTICLES_ERROR,
  GET_ARTICLES_SUCCESS,
} from '../actions/articles-actions';

export interface ArticlesState {
  articles: Article[];
  loading: boolean;
  error?: string;
}

export type ArticlesActions =
  | GetArticlesAction
  | GetArticlesErrorAction
  | GetArticlesSuccessAction;

const initialState: ArticlesState = { articles: [], loading: false };

export const articlesReducer: Reducer<ArticlesState, ArticlesActions> = (
  state = initialState,
  action,
) => {
  if (action.type === GET_ARTICLES) {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }
  if (action.type === GET_ARTICLES_SUCCESS) {
    return {
      articles: action.articles,
      loading: false,
      error: undefined,
    };
  }
  if (action.type === GET_ARTICLES_ERROR) {
    return {
      articles: [],
      loading: false,
      error: action.error,
    };
  }

  return state;
};
