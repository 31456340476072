import { API } from '@/utils/api';
import { Article } from '@/models/articles';

export const getArticles = async (locale: string) => {
  const {
    data: { articles },
  } = await await API.get<{ articles: Article[] }>(
    `get-articles?locale=${encodeURIComponent(locale)}`,
  );
  return articles;
};

export const getArticle = async (slug: string, locale: string) => {
  const { data } = await await API.get<Article>(
    `get-article?slug=${encodeURIComponent(slug)}&locale=${encodeURIComponent(
      locale,
    )}`,
  );
  return data;
};
