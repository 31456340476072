import { Meta } from '@/models/meta';
import { Reducer } from 'redux';

import { SetMetaAction, SET_META } from '../actions/meta-actions';

const initialState: Meta = {
  title: '',
};

export type MetaActions = SetMetaAction;

export const metaReducer: Reducer<Meta, MetaActions> = (
  state = initialState,
  action,
) => {
  if (action.type === SET_META) {
    return action.payload;
  }

  return state;
};
