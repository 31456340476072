import { getArticles } from '@/services/articles-service';
import { Article } from '@/models/articles';
import { call, put } from '@redux-saga/core/effects';
import {
  GetArticlesAction,
  getArticlesErrorAction,
  getArticlesSuccessAction,
} from '../actions/articles-actions';
import { handleError } from './utils';

export function* articlesSaga({ locale }: GetArticlesAction) {
  try {
    const articles: Article[] = yield call(getArticles, locale);
    yield put(getArticlesSuccessAction(articles));
  } catch (error) {
    yield handleError(error, getArticlesErrorAction);
  }
}
