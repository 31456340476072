/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onRenderBody = require('./src/config/gatsby-browser').onRenderBody;

export const wrapRootElement =
  require('./src/config/gatsby-browser').wrapRootElement;
