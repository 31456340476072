import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { AuthActions, AuthState } from './reducers/auth-reducer';
import { ThemeActions, ThemeState } from './reducers/theme-reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootSaga } from './sagas';
import rootReducer from './reducers';
import { ArticlesActions, ArticlesState } from './reducers/articles-reducer';
import { Meta } from '@/models/meta';

export interface State {
  auth: AuthState;
  theme: ThemeState;
  articles: ArticlesState;
  meta: Meta;
}

export type Actions = ThemeActions | AuthActions | ArticlesActions;

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  return {
    ...createStore(
      rootReducer,
      composeWithDevTools(applyMiddleware(sagaMiddleware)),
    ),
    runSaga: sagaMiddleware.run(rootSaga),
  };
};

export default configureStore;
