import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';

import createStore from '@/store/store';

// eslint-disable-next-line react/display-name,react/prop-types
const WrapStoreWithProvider: FunctionComponent = ({ children }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();

  return <Provider store={store}>{children}</Provider>;
};

export default WrapStoreWithProvider;
