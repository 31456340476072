import { takeLatest } from 'redux-saga/effects';
import {
  registerSaga,
  loginSaga,
  loginAutoSaga,
  resetPasswordSaga,
} from './auth-saga';
import {
  LOGIN_AUTO_USER,
  LOGIN_USER,
  REGISTER_USER,
  RESET_PASSWORD_USER,
} from '../actions/auth-actions';
import { GET_ARTICLES } from '../actions/articles-actions';
import { articlesSaga } from './articles-saga';

export default function* watchUserAuthentication() {
  yield takeLatest(REGISTER_USER, registerSaga);
  yield takeLatest(LOGIN_USER, loginSaga);
  yield takeLatest(LOGIN_AUTO_USER, loginAutoSaga);
  yield takeLatest(RESET_PASSWORD_USER, resetPasswordSaga);
  yield takeLatest(GET_ARTICLES, articlesSaga);
}
