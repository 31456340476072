import { User } from '@/utils/types';
import { Reducer } from 'redux';
import {
  LoginUserSuccessAction,
  LoginUserErrorAction,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LoginUserAction,
  LOGIN_USER,
  LogoutUserAction,
  RegisterUserAction,
  REGISTER_USER,
  LOGOUT_USER,
  LOGIN_AUTO_USER,
  LoginAutoUserAction,
  ResetPasswordUserAction,
  RESET_PASSWORD_USER,
} from '../actions/auth-actions';

export interface AuthState {
  user?: User;
  isLoggedIn: boolean;
  isAdmin: boolean;
  loading: boolean;
  error?: string;
}

export type AuthActions =
  | LoginUserAction
  | LoginAutoUserAction
  | LoginUserSuccessAction
  | LoginUserErrorAction
  | RegisterUserAction
  | ResetPasswordUserAction
  | LogoutUserAction;

const initialState: AuthState = {
  isLoggedIn: false,
  isAdmin: false,
  loading: false,
};

export const authReducer: Reducer<AuthState, AuthActions> = (
  state = initialState,
  action,
) => {
  if (
    action.type === LOGIN_USER ||
    action.type === REGISTER_USER ||
    action.type === RESET_PASSWORD_USER ||
    action.type === LOGIN_AUTO_USER
  ) {
    return {
      ...state,
      loading: true,
      error: undefined,
    };
  }
  if (action.type === LOGIN_USER_SUCCESS) {
    return {
      user: action.user,
      isLoggedIn: true,
      isAdmin: action.user.role === 'admin',
      loading: false,
      error: undefined,
    };
  }
  if (action.type === LOGIN_USER_ERROR) {
    return {
      user: undefined,
      isLoggedIn: false,
      isAdmin: false,
      loading: false,
      error: action.error,
    };
  }
  if (action.type === LOGOUT_USER) {
    return {
      user: undefined,
      isLoggedIn: false,
      isAdmin: false,
      loading: false,
    };
  }
  return state;
};
